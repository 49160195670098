import React from 'react'
import { useAtom } from 'jotai'
import { currentObjectAtom, oasAtom } from 'store'
import { Divider, Header, Image, Segment } from 'semantic-ui-react'

import { RouteComponentProps } from 'react-router-dom'
import { capitalize } from 'utils'
import SpacedContainer from 'styles/common'
import { ActivityType, SkillType } from 'components/Main/Activity/types'
import ObjectList from '../ObjectList/ObjectList'
import { LearningObject } from 'store/types'

type MatchParams = {
  level: ActivityType
  skill: SkillType
}

const learningBannerSrc = `/assets/img/learining-banner.jpg`

const ActivityList = ({ history, match }: RouteComponentProps<MatchParams>): JSX.Element => {
  const [oas] = useAtom(oasAtom)

  const { level, skill } = match.params

  const listOAs = oas[level][skill]
  const [, setCurrentOA] = useAtom(currentObjectAtom)

  const setCurrentObject = (object: LearningObject) => {
    setCurrentOA(object)
    history.push(`${skill}/object-detail`)
  }

  return (
    <div>
      <Segment>
        <SpacedContainer>
          <Header>
            {level}: {capitalize(skill)}
          </Header>
          <Image src={learningBannerSrc} size="medium" alt="banner" verticalAlign="middle" />
        </SpacedContainer>

        <Divider fitted />

        <ObjectList onClick={setCurrentObject} list={listOAs} />
      </Segment>
    </div>
  )
}

export default ActivityList
