import styled from 'styled-components'
import { Carousel as ReactCarousel } from 'react-responsive-carousel'
import pacdiColors from 'styles/common/pacdi_colors'

const Carousel = styled(ReactCarousel)`
  .carousel .slide {
    text-align: left !important;
  }

  .carousel .control-dots .dot {
    background: ${pacdiColors.purple_1};
  }
`
export default Carousel
