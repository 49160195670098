import React from 'react'
import { Image } from 'semantic-ui-react'
import Container from 'styles/App/Activity'

type ActivitySimpleType = {
  title: string
  url: string
  image: string
}

const ActivitySimple = ({ title, url, image }: ActivitySimpleType): JSX.Element => {
  return (
    <Container flexDirection="initial">
      <Image
        as="a"
        href={url}
        target="_blank"
        alt={title}
        src={`${process.env.PUBLIC_URL}/assets/img/activities/${image}`}
      />
    </Container>
  )
}

export default ActivitySimple
