import { atom } from 'jotai'
import { loadingAtom } from 'store'
import { currentUser } from 'store/auth'
import { oasAtom, oasNamesAtom, oasCareerAtom, oasCareerNamesAtom } from 'store'
import { User, UserBase } from 'store/types'
import defaultOAs from 'data/oas.json'

const API_URL = process.env.REACT_APP_API
const API_ACTIVITIES_URL = process.env.REACT_APP_ACTIVITIES_URL

export const fetchLogin = atom(null, async (_get, set, data: UserBase) => {
  set(loadingAtom, true)
  const body = JSON.stringify(data)

  const response = await fetch(`${API_URL}/auth/login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body
  })

  const user: User = await response.json()
  set(currentUser, user)
  set(loadingAtom, false)
})

export const fetchGeneralOAs = atom(null, async (_get, set) => {
  set(loadingAtom, true)
  
  const activities = await performFetch(`${API_ACTIVITIES_URL}/api/v1/activities`, defaultOAs)
  set(oasAtom, activities)
  set(oasNamesAtom, Object.keys(activities))
  
  set(loadingAtom, false)
})

export const fetchCareerOAs = atom(null, async (_get, set) => {
  set(loadingAtom, true)

  const careerActivities = await performFetch(`${API_ACTIVITIES_URL}/api/v1/career_activities`, {})
  set(oasCareerAtom, careerActivities)
  set(oasCareerNamesAtom, Object.keys(careerActivities))
  
  set(loadingAtom, false)
})

const performFetch = async (url:string, defaultValue: object): Promise<any> => {
  let result = defaultValue
  try {
    const response = await fetch(url)
    result = await response.json()
  } catch (e) {
    console.error('- Something went wrong -\n', e)
  }
  return result
}