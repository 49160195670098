import { atom } from 'jotai'
import oas from 'data/oas.json'
import {careers} from 'data/careers'
import resources from 'data/resources'
import { isTeacher } from 'store/auth'
import { LearningObject } from './types'

// OAs List
const oasAtom = atom(oas)
const oasCareerAtom = atom(careers)

// List of Levels [ 'A1', 'A2', .. ]
const oasNamesAtom = atom(Object.keys(oas))
const oasCareerNamesAtom = atom(Object.keys(careers))

// Resources like 'Teacher Resources' | 'TOEFL Practice'
const resourcesAtom = atom((get) => {
  const teacherInSession = get(isTeacher)

  const allowedActivities = resources.filter((r) => {
    if (r.teacherOnly) return teacherInSession

    return true
  })

  return allowedActivities
})

// Full activities names list
const carouselActivitiesAtom = atom((get) => {
  const resNames = get(resourcesAtom).map((r) => r.name)
  const oaNames = get(oasNamesAtom).map((title) => `Level ${title}`)
  return [...resNames, ...oaNames]
})

const defaultObject: LearningObject = {
  level: 'No level',
  title: 'Pacdi Labs',
  surveyUrl: '/',
  type: 'Skill'
}
const currentObjectAtom = atom(defaultObject)

export { oasNamesAtom, oasAtom, oasCareerAtom, oasCareerNamesAtom, currentObjectAtom, carouselActivitiesAtom, resourcesAtom }
