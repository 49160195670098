import { useAtom } from 'jotai'
import React from 'react'
import { Segment } from 'semantic-ui-react'
import { currentUser } from 'store'

const Account = (): JSX.Element => {
  const [user] = useAtom(currentUser)
  return (
    <Segment>
      <h1>Account</h1>
      <p>id: {user.id}</p>
      <p>name: {user.name}</p>
      <p>lastname: {user.lastname}</p>
      <p>username: {user.username}</p>
      <p>Type: {user.type}</p>
    </Segment>
  )
}

export default Account
