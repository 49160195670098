import React from 'react'
import { Header, List, Image, Divider } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { ActivityProps, ActivityType, SkillType } from './types'
import { oasAtom } from 'store'
import { useAtom } from 'jotai'
import { capitalize } from 'utils'

type ItemListProps = {
  levelName: ActivityType
  skill: SkillType
}

const ListItem = ({ levelName, skill }: ItemListProps) => {
  const [oas] = useAtom(oasAtom)

  const level = oas[levelName]
  const src = `${process.env.PUBLIC_URL}/assets/img/activities/${skill}.png`
  const disabled = !level[skill].length

  const displayName = capitalize(skill)
  return (
    <>
      {disabled ? (
        <List.Item>
          <Image avatar src={src} alt={displayName} />
          <List.Content>
            <Header disabled>{displayName}</Header>
          </List.Content>
        </List.Item>
      ) : (
        <List.Item as={Link} to={`/${levelName}/${skill}`}>
          <Image avatar src={src} alt={displayName} />
          <List.Content>
            <Header>{displayName}</Header>
          </List.Content>
        </List.Item>
      )}
    </>
  )
}

const ActivityList = ({ title }: ActivityProps): JSX.Element => (
  <>
    <Image
      src={`${process.env.PUBLIC_URL}/assets/img/activities/${title}.png`}
      alt={title}
      size="tiny"
    />
    <Divider />
    <List divided verticalAlign="middle">
      <ListItem levelName={title} skill="grammar" />
      <ListItem levelName={title} skill="listening" />
      <ListItem levelName={title} skill="reading" />
      <ListItem levelName={title} skill="vocabulary" />
    </List>
    <Divider />
  </>
)

export default ActivityList
