import React from 'react'
import { Grid, Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { useAtom } from 'jotai'
import { oasAtom } from 'store'
import { ActivityProps, ActivityType, SkillType } from './types'

type ImageLinkProps = {
  currentLevel: ActivityType
  skill: SkillType
}

const ImageLink = ({ currentLevel, skill }: ImageLinkProps) => {
  const [oas] = useAtom(oasAtom)

  const level = oas[currentLevel]
  const src = `${process.env.PUBLIC_URL}/assets/img/activities/${skill}.png`
  const disabled = !level[skill].length
  return (
    <>
      {disabled ? (
        <Image src={src} alt={skill} disabled={disabled} />
      ) : (
        <Image as={Link} src={src} alt={skill} to={`/${currentLevel}/${skill}`} />
      )}
    </>
  )
}

const ActivityGrid = ({ title }: ActivityProps): JSX.Element => {
  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width="4">
          <ImageLink currentLevel={title} skill="grammar" />
        </Grid.Column>
        <Grid.Column width="2" />
        <Grid.Column width="4">
          <Image src={`${process.env.PUBLIC_URL}/assets/img/activities/${title}.png`} alt={title} />
        </Grid.Column>
        <Grid.Column width="2" />
        <Grid.Column width="4">
          <ImageLink currentLevel={title} skill="listening" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="3" />
        <Grid.Column width="4">
          <ImageLink currentLevel={title} skill="reading" />
        </Grid.Column>
        <Grid.Column width="2" />
        <Grid.Column width="4">
          <ImageLink currentLevel={title} skill="vocabulary" />
        </Grid.Column>
        <Grid.Column width="3" />
      </Grid.Row>
    </Grid>
  )
}

export default ActivityGrid
