import styled from 'styled-components'
import { NavLink as Link } from 'react-router-dom'
import { colors } from 'styles/common/variables'
import { Button, Menu } from 'semantic-ui-react'

const NavMenu = styled(Menu)`
  margin: 0 !important;
`

const NavLink = styled(Link)`
  font-size: 1.1rem;
  height: 100%;

  &.active,
  &:hover {
    border-color: ${colors.blue} !important;
  }
`
const LogoNavImage = styled.img`
  width: 200px !important;
`

const RightFloatButton = styled(Button)`
  position: absolute;
  right: 0;
  margin: 0 !important;
`

export { NavLink, NavMenu, LogoNavImage, RightFloatButton }
