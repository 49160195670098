import styled from 'styled-components'

type ContainerProps = {
  isMobile?: boolean
  flexDirection: 'initial' | 'column'
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  justify-content: ${(props) => (props.isMobile ? 'top' : 'center')};
  align-items: ${(props) => (props.isMobile ? 'unset' : 'center')};
  width: 100% !important;
  height: 100%;
  background-color: white;
`

export default Container
