import React from 'react'
import { Header, List, Image } from 'semantic-ui-react'

const closeActivitySrc = `/assets/img/close-activity.png`
const activityValidationSrc = `/assets/img/activity-validation.png`

export type MessageProps = {
  surveyUrl: string
}

const InstructionsMessage = ({ surveyUrl }: MessageProps): JSX.Element => {
  const hasLink = surveyUrl && surveyUrl.length > 1

  return (
    <>
      {hasLink && (
        <div>
          <p>
            When you finish the exercises, close the activity window to answer the questionnaire
            that validates your work, as the example below.
          </p>
          <Image src={closeActivitySrc} size="huge" alt="close-activity" centered />
        </div>
      )}

      {!hasLink && (
        <div>
          <p>
            When you finish the exercises, please be sure to answer the questionnaire that validates
            your work.
          </p>
          <Image src={activityValidationSrc} size="huge" alt="activity-validation" centered />
        </div>
      )}
    </>
  )
}

const WorkCompleteMessage = ({ surveyUrl }: MessageProps): JSX.Element => {
  const hasLink = surveyUrl && surveyUrl.length > 1

  return (
    <div>
      <Header as="h5">Did you complete the activities?</Header>
      <List>
        <List.Item as="li">
          {hasLink && (
            <div>
              Answer{' '}
              <strong>
                <a href={surveyUrl} target="_blank" rel="noopener noreferrer">
                  this questionnaire
                </a>{' '}
              </strong>
              to validate your work
            </div>
          )}

          {!hasLink && <InstructionsMessage surveyUrl={surveyUrl} />}
        </List.Item>
      </List>
    </div>
  )
}

export { InstructionsMessage, WorkCompleteMessage }
