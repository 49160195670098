import React, { useEffect } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { useAtom } from 'jotai'
import Login from 'components/Auth/Login/Login'
import Main from 'components/Main/Main'
import Navigation from 'components/Navigation/Navigation'
import UserAccount from 'components/UserAccount/UserAccount'
import ObjectDetail from 'components/activities/laboratories/ObjectDetail/ObjectDetail'
import ActivityList from 'components/activities/laboratories/ActivityList'
import { fetchCareerOAs, fetchGeneralOAs } from 'store/api'
import CareerList from 'components/Main/Career/CareerList'

const AppRouter = (): JSX.Element => {
  const [, getGeneralActivities] = useAtom(fetchGeneralOAs)
  const [, getCareerActivities] = useAtom(fetchCareerOAs)
  
  useEffect(() => {
    getGeneralActivities()
    getCareerActivities()
  }, [getGeneralActivities, getCareerActivities])
  
  return (
  <Router basename={process.env.REACT_APP_ROUTER_BASE || ''}>
    <Navigation />
    <Switch>
      <Route exact path="/" component={Main} />
      <Route exact path="/:level/:skill" component={ActivityList} />
      <Route path="/:level/:skill/object-detail" component={ObjectDetail} />
      <Route exact path="/career" component={CareerList} />
      <Route exact path="/my-account" component={UserAccount} />
      <Route path="/login" component={Login} />
    </Switch>
  </Router>
)}

export default AppRouter
