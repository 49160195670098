import React from 'react'

type IFrameProps = {
  src: string
}

const IFrame = ({ src }: IFrameProps): JSX.Element => {
  const { innerWidth: width, innerHeight: height } = window

  return <iframe title="activity" src={src} width={width} height={height - 108} />
}

export default IFrame
