/* eslint-disable react/no-children-prop */
import React, { useState, useEffect } from 'react'
import { Dropdown, Grid, Segment } from 'semantic-ui-react'
import { useAtom } from 'jotai'
import { carouselActivitiesAtom, oasNamesAtom, resourcesAtom } from 'store'
import { isMobileScreen } from 'utils'
import Carousel from 'styles/App/Carousel'
import ArrowButton from 'components/Navigation/ArrowButton'
import Activity from './Activity'
import ActivitySimple from './Activity/ActivitySimple'
import { ActivityType } from './Activity/types'

type OptionType = {
  key: string
  text: string
  value: number
}

const Main = (): JSX.Element => {
  const [current, setCurrent] = useState(0)
  const [isMobile, setIsMobile] = useState(isMobileScreen())

  const [activityNames] = useAtom(carouselActivitiesAtom) // [ "TOEFL Practice", "A1", ...]
  const [oaNames] = useAtom(oasNamesAtom) // [ "A1", "A2", ... ]
  const [resources] = useAtom(resourcesAtom) // [ {..}, {..} ...]

  const onRezise = () => setIsMobile(isMobileScreen())

  useEffect(() => {
    window.addEventListener('resize', onRezise, false)
    const currentLS = localStorage.getItem('ActivityIndex')
    const index = currentLS ? Number(currentLS) : 0
    setCurrent(index)

    return () => window.removeEventListener('resize', onRezise)
  }, [])

  const options: OptionType[] = activityNames.map((key, index) => ({
    key,
    text: key,
    value: index
  }))

  const OPTIONS_SIZE = activityNames.length
  const onChangeItem = (index: number) => {
    setCurrent(index)
    localStorage.setItem('ActivityIndex', `${index}`)
  }
  const previous = () => setCurrent(current - 1)
  const next = () => setCurrent(current + 1)

  const resourcesList = () =>
    resources.map((r) => <ActivitySimple key={r.name} title={r.name} url={r.url} image={r.image} />)

  const OAList = () =>
    oaNames.map((activity: ActivityType | any) => <Activity key={activity} title={activity} />)

  return (
    <>
      <Carousel
        emulateTouch={isMobile}
        useKeyboardArrows
        showThumbs={false}
        showArrows={false}
        selectedItem={current}
        showStatus={false}
        onChange={onChangeItem}
        children={[...resourcesList(), ...OAList()]}
      />

      {isMobile && (
        <Segment textAlign="center">
          <Dropdown
            selection
            value={current}
            options={options}
            onChange={(e, { value }) => {
              if (typeof value === 'number') onChangeItem(value)
            }}
          />
        </Segment>
      )}
      {!isMobile && (
        <Segment>
          <Grid columns={3} textAlign="center">
            <Grid.Row>
              <Grid.Column>
                <ArrowButton icon="angle left" disabled={current === 0} onClick={previous} />
              </Grid.Column>
              <Grid.Column>
                <Dropdown
                  selection
                  value={current}
                  options={options}
                  onChange={(e, { value }) => {
                    if (typeof value === 'number') onChangeItem(value)
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <ArrowButton
                  icon="angle right"
                  disabled={current === OPTIONS_SIZE - 1}
                  onClick={next}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      )}
    </>
  )
}

export default Main
