import React from 'react'
import { LearningObject } from 'store/types'
import { Divider, List } from 'semantic-ui-react'

type ObjectListParams = {
  list: LearningObject[]
  onClick: (object: LearningObject) => void
}

const ObjectList = ({ onClick, list }: ObjectListParams): JSX.Element => {
  return (
    <List>
      {list.map((object) => (
        <List.Item key={object.id} onClick={() => onClick(object)} style={{ cursor: 'pointer' }}>
          <List.Icon name="angle right" />
          <List.Content>
            <List.Header>{object.title}</List.Header>
          </List.Content>
          <Divider />
        </List.Item>
      ))}
    </List>
  )
}

export default ObjectList
