import React from 'react'
import { Header, Menu, Dropdown } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import { useAtom } from 'jotai'
import { LogoNavImage, NavLink, NavMenu } from 'styles/App'
import { logout, isLoggedIn, currentUser } from 'store'

const Navigation = (): JSX.Element => {
  const [loggedIn] = useAtom(isLoggedIn)
  const [, logoutUser] = useAtom(logout)
  const [user] = useAtom(currentUser)

  return (
    <NavMenu pointing secondary>
      <Menu.Item name="home" as={NavLink} to="/" exact>
        <LogoNavImage src={`${process.env.PUBLIC_URL}/assets/img/PACDI.jpg`} />
      </Menu.Item>
      <Menu.Item name="career" as={NavLink} to="/career" exact>
        <Header color="brown">Career Activities</Header>
      </Menu.Item>
      
      <Menu.Menu position="right">
        {loggedIn ? (
          <Dropdown text={`${user.name} ${user.lastname}`} pointing className="link item">
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="/my-account">
                My Account
              </Dropdown.Item>

              <Dropdown.Item as={Link} to="/" onClick={logoutUser}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <Menu.Item name="login" as={NavLink} to="/login" exact>
            <Header color="brown">Login</Header>
          </Menu.Item>
        )}
      </Menu.Menu>
    </NavMenu>
  )
}

export default withRouter(Navigation)
