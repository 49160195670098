import React from 'react'
import { useAtom } from 'jotai'
import { RouteComponentProps } from 'react-router-dom'
import { Segment, Header, Divider, Image } from 'semantic-ui-react'
import { oasCareerAtom, currentObjectAtom } from 'store'
import { LearningObject } from 'store/types'
import ObjectList from 'components/activities/laboratories/ObjectList/ObjectList'
import SpacedContainer from 'styles/common'

const learningBannerSrc = `/assets/img/learining-banner.jpg`

const CareerList = ({ history }: RouteComponentProps): JSX.Element => {
    document.title = "Career Activities"
    const [activities] = useAtom(oasCareerAtom)
    const levels = Object.keys(activities)

    const [, setCurrentOA] = useAtom(currentObjectAtom)

    const setCurrentObject = (object: LearningObject) => {
      setCurrentOA(object)
      history.push(`${object.type}/${object.level}/object-detail`)
    }
    
    return (
        <Segment>
            <SpacedContainer>
                <Header>Career Activities</Header>
                <Image src={learningBannerSrc} size="medium" alt="banner" verticalAlign="middle" />
            </SpacedContainer>
            <Divider/>
            {
                levels.map(level => {
                    const listOAs:LearningObject[] = activities[level]
                    if(listOAs.length === 0)
                        return null

                    return (
                        <div key={level}>
                            <Image
                                src={`${process.env.PUBLIC_URL}/assets/img/activities/${level}.png`}
                                alt={level}
                                size="tiny"
                            />
                            <Divider fitted/>
                            <ObjectList onClick={setCurrentObject} list={listOAs} />
                        </div>
                    )
                })
            }
        </Segment>
    )
}

export default CareerList