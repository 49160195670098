const resources = [
  {
    name: 'Teacher Resources',
    url: 'https://www.facebook.com/UAZzacatecas/',
    image: 'conversation.png',
    teacherOnly: true
  },
  {
    name: 'TOEFL Practice',
    url: 'https://www.ets.org/toefl/test-takers/ibt/prepare/tests',
    image: 'toefl-practices.png',
    teacherOnly: false
  }
]

export default resources
