import React, { useEffect, useState } from 'react'
import { isMobileScreen } from 'utils'
import Container from 'styles/App/Activity'
import ActivityGrid from './ActivityGrid'
import ActivityList from './ActivityList'
import { ActivityProps } from './types'

const Activity = ({ title }: ActivityProps): JSX.Element => {
  const [isMobile, setIsMobile] = useState(isMobileScreen())

  const onRezise = () => setIsMobile(isMobileScreen())

  useEffect(() => {
    document.title = "PACDI Labs"
    window.addEventListener('resize', onRezise, false)
    return () => window.removeEventListener('resize', onRezise)
  }, [])

  return (
    <Container flexDirection="column" isMobile={isMobile}>
      {isMobile && <ActivityList title={title} />}
      {!isMobile && <ActivityGrid title={title} />}
    </Container>
  )
}

export default Activity
