// PALETTON.COM #A78E70
// https://paletton.com/#uid=70E0u0kaykY4iuN7upkcXgriFdf
const pacdiColors = {
  brown_0: '#A78E70', // Brown color */
  brown_1: '#F5E6D4',
  brown_2: '#CAB49B',
  brown_3: '#836B4E',
  brown_4: '#6A4D2C',

  golden_0: '#A79870', // Golden color */
  golden_1: '#F5ECD4',
  golden_2: '#CABD9B',
  golden_3: '#83744E',
  golden_4: '#6A592C',

  purple_0: '#525673', // Purple color */
  purple_1: '#9698A9',
  purple_2: '#6F728B',
  purple_3: '#3B3E5A',
  purple_4: '#242849',

  blue_0: '#495E6A', // Blue color */
  blue_1: '#88959C',
  blue_2: '#647680',
  blue_3: '#334853',
  blue_4: '#1E3643'
}

export default pacdiColors
