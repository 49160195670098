import styled from 'styled-components'
import { Segment, Image } from 'semantic-ui-react'

const Container = styled(Segment)`
  display: flex !important;
  flex-grow: 1;
  flex-direction: column;
  margin: 0 !important;
  align-items: center;
  justify-content: center;
  background-color: #b5c7e0 !important;
`
const WelcomeImg = styled(Image)`
  width: 440px !important;
`
const UazLogoImg = styled(Image)`
  width: 190px !important;
`
export { Container, WelcomeImg, UazLogoImg }
