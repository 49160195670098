import pacdiColors from './pacdi_colors'

const colors = {
  blue: '#009dff',
  ...pacdiColors
}

const backgrounds = {
  bgLightBrown: colors.brown_1,
  bgContentLight: `#F67879`,
  bgContentLightest: `#FDDAD2`
}

export { colors, backgrounds }
