import React from 'react'
import { Button } from 'semantic-ui-react'

type ArrowButtonProps = {
  disabled: boolean
  icon: string
  onClick: () => void
}

const ArrowButton = ({ disabled, icon, onClick }: ArrowButtonProps): JSX.Element => (
  <Button circular disabled={disabled} onClick={onClick} size="big" color="olive" icon={icon} />
)

export default ArrowButton
