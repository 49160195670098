import React, { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { Grid, Button, Form, FormGroup } from 'semantic-ui-react'
import { RouteChildrenProps } from 'react-router-dom'
import { fetchLogin } from 'store/api'
import { currentUser, loadingAtom } from 'store'
import { Container, WelcomeImg, UazLogoImg } from 'styles/auth'

const Login = ({ history }: RouteChildrenProps): JSX.Element => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const [loading] = useAtom(loadingAtom)
  const [user] = useAtom(currentUser)
  const [, login] = useAtom(fetchLogin)

  const nonEmpties = [username, password].filter(Boolean).length === 2

  useEffect(() => {
    if (user.id) {
      history.push('/')
    }
  }, [user, history])

  const onLogin = () => {
    login({ username, password })
  }

  return (
    <Grid columns={3} stackable loading={loading}>
      <Grid.Row>
        <Grid.Column />
        <Grid.Column align="center">
          <WelcomeImg src={`${process.env.PUBLIC_URL}/assets/img/login/Welcome_login.png`} />
          <Container>
            <Form>
              <FormGroup>
                <Form.Input
                  label="Username"
                  name="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Form.Input
                  label="Password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormGroup>
            </Form>
            <Button color="blue" type="submit" disabled={!nonEmpties} onClick={onLogin}>
              Login
            </Button>
          </Container>
        </Grid.Column>
        <Grid.Column> </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column> </Grid.Column>
        <Grid.Column> </Grid.Column>
        <Grid.Column align="right">
          <UazLogoImg src="/assets/img/login/Uaz_logo.png" />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default Login
