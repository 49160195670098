import React, { Suspense} from 'react'
import AppRouter from 'components/routes/AppRouter'
import { Provider } from 'jotai'

function App(): JSX.Element {
  return (
    <Suspense fallback={<span>Loading</span>}>
      <Provider>
        <AppRouter />
      </Provider>
    </Suspense>
  )
}

export default App
