import { LearningObject } from "store/types"

export type CareerActivities = {
  [key: string]: LearningObject[]
}

const careers:CareerActivities = {
  "A1": [],
  "A2": [],
  "B1": [],
  "B2": []
}

export {
  careers
}