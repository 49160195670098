import React, { useEffect, useState } from 'react'
import IFrame from 'components/activities/utils/IFrame'
import { useAtom } from 'jotai'
import { currentObjectAtom } from 'store'
import { Button, Divider, Header, Image, Segment } from 'semantic-ui-react'
import { RightFloatButton } from 'styles/App'
import { RouteChildrenProps } from 'react-router-dom'
import SpacedContainer from 'styles/common'
import { capitalize } from 'utils'
import { InstructionsMessage, WorkCompleteMessage } from './InstructionsMessage'

const learningBannerSrc = `/assets/img/learining-banner.jpg`

const ObjectDetail = ({ history }: RouteChildrenProps): JSX.Element => {
  const [isObjectOpen, setIsObjectOpen] = useState(false)
  const [finished, setFinished] = useState(false)
  const [currentOA] = useAtom(currentObjectAtom)

  document.title = currentOA.title
  useEffect(() => {
    console.log('Object:', currentOA)
    // if (!currentOA.id) history.push('/a1')
  }, [history, currentOA])

  const openObject = () => setIsObjectOpen(true)

  const closeObject = () => {
    setIsObjectOpen(false)
    setFinished(true)
  }

  return (
    <div>
      {currentOA.path && isObjectOpen && (
        <Segment style={{ padding: 0 }}>
          <RightFloatButton secondary icon="close" onClick={closeObject} />
          <IFrame src={`${process.env.REACT_APP_ACTIVITIES_URL}${currentOA.path}`} />
        </Segment>
      )}

      {!isObjectOpen && (
        <Segment>
          <SpacedContainer>
            <Header>
              {currentOA.level}: {capitalize(currentOA.type)} - {currentOA.title}
            </Header>
            <Image src={learningBannerSrc} size="medium" alt="banner" verticalAlign="middle" />
          </SpacedContainer>
          <Divider fitted />

          <Segment basic textAlign="center">
            <Segment.Group compact>
              <Segment color="teal" secondary>
                {!finished && <InstructionsMessage surveyUrl={currentOA.surveyUrl} />}

                {finished && <WorkCompleteMessage surveyUrl={currentOA.surveyUrl} />}
              </Segment>
              <Divider fitted />
              <Segment basic textAlign="left">
                {currentOA.id && (
                  <Button color="olive" onClick={openObject}>
                    {finished ? 'Start over!' : 'Begin!'}
                  </Button>
                )}
              </Segment>
            </Segment.Group>
          </Segment>
        </Segment>
      )}
    </div>
  )
}

export default ObjectDetail
