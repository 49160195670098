import { atom } from 'jotai'
import { User } from './types'

const defaultUser: User = {
  id: 0,
  username: '',
  password: '',
  name: 'test',
  lastname: '',
  enrollment: '',
  type: 'Guest'
}

export const currentUser = atom(defaultUser)
export const isTeacher = atom((get) => get(currentUser).type === 'Teacher')
export const isLoggedIn = atom((get) => {
  const user = get(currentUser)
  const loggedIn = user.type !== 'Guest'
  return loggedIn
})
export const logout = atom(null, (_, set) => set(currentUser, defaultUser))
